.App {
  text-align: center;
}
Body {
  /* background-image: url("./assets/images/bg3.png"); */
  background-size: cover;

  /* background-color: #a9e2ed; */
  /* background-color: #dafefb; */
  /* background-color: #dde6ed; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.row {
  display: flex;
  flex-wrap: wrap; /* Allows wrapping of items to the next row */
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-6,
.col-12 {
  /* padding-left: 15px;
  padding-right: 15px; */
  box-sizing: border-box;
}

.col-1 {
  width: 8.33%;
} /* 1/12 of the row */
.col-2 {
  width: 16.66%;
} /* 2/12 of the row */
.col-3 {
  width: 25%;
} /* 3/12 of the row */
.col-4 {
  width: 33.33%;
} /* 4/12 of the row */
.col-6 {
  width: 50%;
} /* 6/12 of the row */
.col-12 {
  width: 100%;
} /* Full row */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.contact-box {
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.3);
}

.search-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.grid-item {
  text-align: start;
  width: 92%;
  padding: 5px;
}

@media (max-width: 800px) {
  .grid-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .check-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

.container {
  /* display: grid; */
  /* grid-template-columns: repeat(2, 1fr); */
  background-image: url("./assets/images/bg1.png");
}
.check-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  background-image: url("./assets/images/bg1.png");
}
.check-item {
  text-align: start;
  width: 100%;
  padding-right: 10px;
}

.group {
  display: flex;
  line-height: 30px;
  align-items: center;
  position: relative;
  /* max-width: 200px; */
  border: 0.1px solid #b3b3b3;
  border-radius: 5px;
  width: 95%;
  transition: 0.3s;
  transform: scale(1.01);
}

.email-group {
  display: flex;
  line-height: 30px;
  align-items: center;
  position: relative;
  /* max-width: 200px; */
  border: 0.1px solid #b3b3b3;
  border-radius: 5px;
  width: 100%;
  transition: 0.3s;
  transform: scale(1.01);
}

.text-box-style {
  width: 100%;
  height: 30px;
  line-height: 30px;
  padding: 0 5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 16px;
  /* border: 0.1px solid #637091; */
  border-radius: 10px;
  border: none;
  outline: none;
  background-color: #fdfdfe;
  color: #0d0c22;
  transition: 0.5s ease;
}
.text-dropdown-style {
  width: 100%;
  height: 30px;
  line-height: 30px;
  padding: 0 5rem;
  padding-left: 0rem;
  padding-right: 0rem;
  font-size: 16px;
  /* border: 0.1px solid #637091; */
  border-radius: 10px;
  border: none;
  outline: none;
  /* background-color: #fdfdfe; */
  color: #0d0c22;
  transition: 0.5s ease;
}

@media only screen and (max-width: 600px) {
  .group {
    display: flex;
    line-height: 40px;
    align-items: center;
    position: relative;
    /* max-width: 200px; */
    border: 0.1px solid #b3b3b3;
    border-radius: 5px;
    /* width: 95%; */
    transition: 0.3s;
    transform: scale(1.01);
  }
  .text-box-style {
    width: 100%;
    height: 40px;
    line-height: 40px;
    padding: 0 5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 16px;
    /* border: 0.1px solid #637091; */
    border-radius: 10px;
    border: none;
    outline: none;
    background-color: #fdfdfe;
    color: #0d0c22;
    transition: 0.5s ease;
  }
  .text-dropdown-style {
    width: 100%;
    height: 40px;
    line-height: 40px;
    padding: 0 5rem;
    padding-left: 0rem;
    padding-right: 0rem;
    font-size: 16px;
    /* border: 0.1px solid #637091; */
    border-radius: 10px;
    border: none;
    outline: none;
    /* background-color: #fdfdfe; */
    color: #0d0c22;
    transition: 0.5s ease;
  }
}

.custom-text-box-style {
  width: 100%;
  height: 30;
  line-height: 25px;
  padding: 0 5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 14px;
  /* border: 0.1px solid #637091; */
  border-radius: 10px;
  border: none;
  outline: none;
  background-color: #fdfdfe;
  color: #0d0c22;
  transition: 0.5s ease;
}

.text-box-style::placeholder {
  color: #94a3b8;
  font-size: 14px;
}

/* .text-box-style:focus, */
.input:hover {
  outline: none;
  /* border-color: #637091; */
  /* background-color: #fff; */
}
.custom-text-box-style::placeholder {
  color: #94a3b8;
  font-size: 12px;
}

/* .custom-text-box-style:focus, */
.input:hover {
  outline: none;
  /* border-color: #637091; */
  /* background-color: #fff; */
}

.text-box-icon-style {
  position: absolute;
  /* background-color: red; */
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  height: 45px;
  width: 7px;
}

.text-error {
  outline: none;
  border-color: red;
}
.helper-text {
  color: red;
  font-size: 12px;
}

.save-button {
  outline: 0;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background: #40b3a2;
  min-width: 150px;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 16px 20px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  /* text-transform: uppercase; */
  overflow: hidden;
  cursor: pointer;
}

.save-button:hover {
  opacity: 0.95;
  transform: scale(1.1);
  transition: all 0.5s;
}

.save-button .animation {
  border-radius: 100%;
  /* animation: ripple 0.6s linear infinite; */
}
/* .save-button:after {
  content: "";
  background: white;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s;
}

.save-button:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
} */
tr {
  border: 1px solid #ddd;
  color: black;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #fff;
  color: #000;
}
tr:nth-child(odd) {
  background-color: #fbfcfe;
  color: #000;
}

tr:hover {
  background-color: #efefefef;
}

th {
  /* padding-top: 12px;
  padding-bottom: 12px; */
  border: 1px solid #fff;
  min-width: 39vh;
  height: 40px;
  text-align: center;
  background-color: #1770e0;
  color: #fff;
  padding: 5px;
}

td {
  /* padding-top: 12px;
  padding-bottom: 12px; */
  text-align: center;
  /* background-color: #fff; */
  color: #000;
  padding: 1px;

  border: 1px solid #fff;
  border-bottom: 1px solid #dedede;
}
.handle-scroll {
  max-height: 90vh;
  overflow-y: auto;
  margin-left: 10px;
}
.navbar-icon {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: end;
  /* margin-left: 10;
  margin-right: 10; */
  /* background-color: red; */
  min-width: 150px;
}
p {
  font-size: 100%;
}

/* .navbar-icon:hover {
  background-color: #284b8c;
  border-radius: 10;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: end;
  margin-left: 1;
  margin-right: 1;
} */
