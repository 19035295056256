/* Base styles for desktop */
.as-inner-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.fields-width {
  minwidth: 210px;
}

.chart-container {
  height: 60vh;
}

@media (max-width: 768px) {
  /* For screens smaller than 768px, e.g., mobile devices */
}
.as-accordion-container {
  min-height: 50vh;
  max-height: 50vh;
  overflow-y: auto;
}

/* iPad (tablet) view */
@media (max-width: 1024px) {
  .as-inner-container {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on iPad */
  }

  .as-accordion-container {
    min-height: 60vh; /* Adjust height for better usability on iPad */
    max-height: 60vh;
  }
}

/* Mobile view */
@media (max-width: 600px) {
  .as-inner-container {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on iPad */
  }

  .as-accordion-container {
    min-height: 70vh; /* Adjust height to fill more of the mobile screen */
    max-height: 70vh;
  }
  .fields-width {
    min-width: 100px;
  }
}

/* From Uiverse.io by akshat-patel28 */
.email-left-card {
  width: 280px;
  height: 150px;
  background: #1770e0;
  border-radius: 15px;
  box-shadow: rgb(0, 0, 0, 0.1) 5px 10px 50px, rgb(0, 0, 0, 0.1) -5px 0px 250px;
  display: flex;
  color: white;
  justify-content: center;
  position: relative;
  flex-direction: column;
  background: linear-gradient(to right, #1770e0, #5696e3);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}
.contact-card-left-card {
  width: 280px;
  height: 150px;
  background: #6a42c2;
  border-radius: 15px;
  box-shadow: rgb(0, 0, 0, 0.1) 5px 10px 50px, rgb(0, 0, 0, 0.1) -5px 0px 250px;
  display: flex;
  color: white;
  justify-content: center;
  position: relative;
  flex-direction: column;
  background: linear-gradient(to right, #6a42c2, #9c81e1);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

.email-left-card:hover {
  box-shadow: rgb(0, 0, 0, 0.3) 5px 10px 50px, rgb(0, 0, 0, 0.3) -5px 0px 250px;
}

.contact-card-left-card:hover {
  box-shadow: rgb(0, 0, 0, 0.3) 5px 10px 50px, rgb(0, 0, 0, 0.3) -5px 0px 250px;
}

.time-text {
  font-size: 50px;
  margin-top: 0px;
  margin-left: 15px;
  font-weight: 600;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.time-sub-text {
  font-size: 15px;
  margin-left: 5px;
}

.day-text {
  font-size: 18px;
  margin-top: 0px;
  margin-left: 15px;
  font-weight: 500;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.moon {
  font-size: 20px;
  position: absolute;
  right: 15px;
  top: 15px;
  transition: all 0.3s ease-in-out;
}

.card:hover > .moon {
  font-size: 23px;
}
