.search-container {
  box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.1);
  background-image: url("../assets/images/bg1.png");
  /* min-height: 400px; */
  /* max-height: 400px; */
  min-height: 70vh;
  max-height: 70vh;
  margin-top: 20px;
  overflow-y: auto;
  overflow: scroll;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: white;
  padding: 40px;
  padding-top: 25px;
  margin-right: 40px;
  margin-left: 40px;
  border-radius: 10px;
}
.search-container::-webkit-scrollbar {
  /* display: none; */
  width: 10px;
}

/* Hide scrollbar for IE and Edge */
.search-container {
  -ms-overflow-style: none;
}
.search-card {
  background-image: url("../assets/images/card.png");
  background-size: cover;
  background-color: #fffcdf;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 1px;
  /* padding-top: 15px; */
  padding-left: 20px;
  padding-bottom: 10px;
  min-height: 100px;
  max-height: 100px;
  /* overflow: scroll;
  overflow-y: auto;
  overflow-x: hidden; */
  margin-top: 27px;
  margin-right: 60px;
}

.search-card-in {
  margin-top: 10px;
  min-height: 85px;
  max-height: 85px;
  overflow: scroll;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 15px;
}

.search-card-in::-webkit-slider-thumb {
}
.search-card-in::-webkit-scrollbar-track {
  background: #fafcfd;
  border-radius: 50px;
}
.search-card-in::-webkit-scrollbar-thumb {
  background: #c7cbd0;
  border-radius: 50px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.search-card-in::-webkit-scrollbar {
  /* display: none; */
  width: 10px;
}

/* Hide scrollbar for IE and Edge */
.search-card-in {
  -ms-overflow-style: none;
}
.search-card:hover {
  box-shadow: 0 8px 10px 0 black;
  box-shadow: 0 8px 16px 0 gray;
  transition: all 0.3s;
}

.company-text:hover {
  color: blue;
}
.contact-text:hover {
  color: blue;
}
.search-box {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 18px;
  margin-left: 18px;
}
.contact-bullet {
  background-color: #4c4c4c;
  height: 7px;
  width: 7px;
  border-radius: 5px;
  cursor: pointer;
}
.no-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .search-container {
    box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.1);
    background-image: url("../assets/images/bg1.png");
    /* min-height: 400px; */
    /* max-height: 400px; */
    max-height: 80vh;
    min-height: 80vh;
    margin-top: 10px;
    overflow: scroll;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: white;
    padding: 40px;
    padding-top: 25px;
    margin-right: 20px;
    margin-left: 20px;
    border-radius: 10px;
  }
  .search-box {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin-top: 18px;
    width: 100%;

    /* margin-left: 18px; */
  }
  .search-card {
    background-image: url("../assets/images/card.png");
    background-size: cover;
    background-color: #fffcdf;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 1px;
    /* padding-top: 15px; */
    padding-left: 20px;
    padding-bottom: 10px;
    min-height: 100px;
    max-height: 100px;
    /* overflow: scroll;
  overflow-y: auto;
  overflow-x: hidden; */
    margin-top: 27px;
    margin-right: 1px;
    margin-left: -30px;
  }
}
