.employee-basic-grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 items per row on large screens */
  gap: 0px 15px;
  /* padding: 16px; */
}

/* Grid item styling */
.employee-grid-item {
  /* margin-top: -20px; */
}

.employee-grid-item:nth-child(n + 5) {
  margin-top: -20px;
}

/* Responsive: 1 item per row on mobile */
@media (max-width: 768px) {
  .employee-basic-grid-container {
    grid-template-columns: 1fr; /* 1 item per row on small screens */
  }
}
