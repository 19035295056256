/* Container to hold both boxes */
.role-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1px;
}

/* Individual box styling */
.box {
  flex: 1 1 48%;
  margin: 5px;
  padding: 10px;
  /* padding: 20px; */
  box-sizing: border-box;
}
.manage-roles-width {
  width: 40%;
}

.role-basic-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 4 items per row on large screens */
  gap: 0px 15px;
  /* display: flex;
  align-items: center; */
  /* padding: 16px; */
}

/* Grid item styling */
.role-grid-item {
  /* margin-top: -20px; */
}

.employee-grid-item:nth-child(n + 5) {
  margin-top: -20px;
}

/* Responsive: 1 item per row on mobile */
@media (max-width: 768px) {
  .employee-basic-grid-container {
    grid-template-columns: 1fr; /* 1 item per row on small screens */
  }
}

/* Mobile view - stack boxes */
@media (max-width: 768px) {
  .box {
    flex: 1 1 100%; /* 100% width for mobile */
  }
}
