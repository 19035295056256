.main-nav {
  width: 90;
  height: 90;
  display: grid;
  grid-template-columns: 10rem 1fr;
  width: 90;
  height: 90;
}
.main-container-scroll {
  overflow-y: auto; /* Allows scrolling */
  scrollbar-width: none; /* For Firefox */
}

.main-container-scroll::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Edge */
}

.menu {
  margin-top: 30;
  display: "flex";

  justify-content: space-between;
  margin-left: 50;
}
.type-cursor {
  cursor: pointer;
}
.logo-img {
  height: 40px;
  width: 140px;
  object-fit: contain;
  /* background-color: white; */
}
.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}
@media only screen and (max-width: 600px) {
  .logo-img {
    height: 50px;
    width: 140px;
    object-fit: contain;
    /* background-color: white; */
  }
  .type-cursor {
    cursor: auto;
  }
}
