.checkbox-col {
  min-width: 16vh;
  max-width: 16vh;
}
.send-mail-inner-container {
  display: flex;
  justify-content: center;
  /* grid-template-columns: repeat(3, 1fr); */
  gap: 10px;
}

.table-container {
  max-height: 65vh;
  min-height: 65vh;
  max-width: 100%;
  margin: 20px;
}
.email-contacts {
  max-height: 65vh;
  min-height: 65vh;

  min-width: 100%;
  max-width: 100%;
  /* overflow-x: hidden; */
}
.scroll-effect {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
  /* padding: 5px; */
  /* background-color: #cae8ca; */
  /* border: 2px solid #4CAF50; */
}

.loading-animation {
  height: 100vh;
  /* margin-top: 65px; */
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.2); /* Slightly gray backdrop */
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999; /* Keep it above other content */
}
.custom-group {
  display: flex;
  line-height: 30px;
  align-items: center;
  position: relative;
  /* max-width: 200px; */
  border: 0.1px solid #b3b3b3;
  border-radius: 5px;
  width: 100%;
  transition: 0.3s;
  transform: scale(1.01);
  background-color: "#fdfdfe";
  width: 100%;
}
.contact-details-container {
  min-height: 100vh;
  background-size: cover;
  background-color: #eceeef;
}
.send-mail-container {
  padding: 20px;
  padding-top: 0;
}
.mail-box {
  margin-top: 10px;
}

.email-modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  background-color: #fff;
  border: 1px solid #7f7f7f;
  box-shadow: 24px;
  border-radius: 5px;
}
.emodal-box {
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-top: 10px;
  margin-right: 15px;
  cursor: pointer;
}
.flex-row-email {
  display: flex;
  flex-direction: row;
}
.attach-box {
  margin-left: 10px;
  cursor: pointer;
}
.file-list {
  width: 95%;
}
.file-container {
  background-color: #edf7ff;
  height: 30px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;
  margin-right: 20px;
  margin-left: 30px;
}
.to-container {
  padding: 40px;
  padding-top: 8px;
}
.to-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.to-textbox {
  width: 10%;
}
.to-input-container {
  width: 90%;
  margin-right: 17px;
}
.editor-container {
  background-color: "#ededed";
}
.loading-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.hidden {
  display: none;
}
td {
  padding-left: 5px;
  padding-right: 5px;
}

.filtered-list-container {
  max-height: 45vh;
}

.rainbow-hover {
  font-size: 16px;
  font-weight: 700;
  color: #ff7576;
  /* background-color: #580724; */
  min-width: 120px;
  /* margin-right: 20px; */
  border: none;
  outline: none;
  cursor: pointer;
  padding: 8px 24px;
  position: relative;
  line-height: 24px;
  border-radius: 9px;
  box-shadow:
    0px 1px 2px #ddd,
    0px 4px 16px #ddd;
  transform-style: preserve-3d;
  transform: scale(var(--s, 1)) perspective(600px) rotateX(var(--rx, 0deg))
    rotateY(var(--ry, 0deg));
  perspective: 600px;
  transition: transform 0.1s;
}

.sp {
  background: #fff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  display: block;
}

.rainbow-hover:active {
  transition: 0.3s;
  transform: scale(0.93);
}

.btn-icon {
  height: 25px;
  width: 25px;
}
.tableth-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media only screen and (max-width: 600px) {
  .send-mail-inner-container {
    display: grid;

    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  .rainbow-hover {
    width: 250px;
  }
  .tableth-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .email-modal-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    background-color: #fff;
    border: 1px solid #7f7f7f;
    box-shadow: 24px;
  }
  .mail-box {
    margin-top: 10px;
    margin-left: 5px;
  }
  .to-container {
    padding: 20px;
    padding-top: 8px;
  }
  .to-box {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }

  .to-input-container {
    width: 100%;
    margin-right: 17px;
  }
  .emodal-box {
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-top: 10px;
    margin-right: 15px;
    cursor: pointer;
  }
  .table-container {
    /* display: flex;
  justify-content: center; */
    max-height: 65vh;
    min-height: 65vh;
    max-width: 100%;
    margin: 5px;
  }
  .email-contacts {
    max-height: 65vh;
    min-height: 65vh;
    /* margin-left: -20px; */
    /* margin-right: 18px; */
    /* margin-top: 18px; */
    min-width: 100%;
    max-width: 100%;
    /* overflow-x: hidden; */
  }
  .handle-scroll {
    max-height: 90vh;
    overflow-y: scroll;
    margin-left: 0px;
  }
  .send-mail-container {
    padding: 5px;
    padding-top: 0;
  }
  .rainbow-hover {
    min-width: 26vw;
    max-width: 26vw;
    padding: 8px 3px;
  }
  .btn-icon {
    height: 20px;
    width: 20px;
  }
}
