/* From Uiverse.io by satyamchaudharydev */
.card {
  --bg: #4c8de6;
  --hover-bg: #1770e0;
  --hover-text: #fff;
  max-width: 23ch;
  text-align: center;
  /* background: var(--bg); */
  color: #1770e0;
  padding: 1.5em;
  padding-block: 1.8em;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
}
.card:hover{
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a subtle shadow effect */
  transform: translateY(-2px); 
}

.card__body {
  color: #6e6b6b;
  line-height: 1.5em;
  font-size: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card > :not(span) {
  transition: 0.3s cubic-bezier(0.6, 0.4, 0, 1);
}

.card > strong {
  display: block;
  font-size: 1.4rem;
  letter-spacing: -0.035em;
}

.card span {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--hover-text);
  border-radius: 5px;
  font-weight: bold;
  top: 100%;
  transition: all 0.3s cubic-bezier(0.6, 0.4, 0, 1);
}
