.admin-container {
  /* display: grid;
  grid-template-columns: repeat(2, 1fr); */
  background-image: url("../assets/images/bg1.png");
  /* background-size: cover; */
  box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.1);
  background-color: white;
  padding-bottom: 27px;
  padding-left: 27px;
  margin-right: 30px;
  margin-left: 30px;
  /* margin-top: 13px; */
  border-radius: 10px;
}
.admin-container-padding {
  padding: 9px;
}
.admin-grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.admin-table-card {
  /* margin-right: 3%;
  margin-left: 3%; */
  max-height: 58vh;
}
.admin-table-container {
  /* border: 1px solid #b7b7b7; */
  min-height: 58vh;
  max-height: 58vh;
}
.admin-btn-container {
  display: flex;
  justify-content: end;
  margin-right: 4%;
  padding: 20px;
}

.edit-line {
  background-color: #666666;
  height: 0.5px;
  margin-left: 30px;
  margin-right: 55px;
  margin-bottom: 10px;
}
.edit-handle-scroll {
  max-height: 100%;
  overflow-y: scroll;
  margin-left: 10px;
}
.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* background-color: green; */
  margin-left: 5%;
}
.delete-modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 45%;
  background-color: #fff;
  border: 1px solid #7f7f7f;
  box-shadow: 24px;
  padding: 20px;
}
.delete-modal-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  margin-right: 15px;
  cursor: pointer;
}
.no-data {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.admin-mail-box {
  margin-top: -5px;
  /* margin-left: 3.5%; */
}
.admin-table-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.admin-grid-item {
  margin-left: 9px;
}
@media only screen and (max-width: 600px) {
  .admin-table-container {
    /* border: 1px solid #b7b7b7; */
    min-height: 60vh;
    max-height: 60vh;
  }
  .admin-mail-box {
    margin-top: -5px;
    margin-left: 0%;
  }
  .admin-grid-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .admin-container {
    /* display: grid;
  grid-template-columns: repeat(2, 1fr); */
    background-image: url("../assets/images/bg1.png");
    /* background-size: cover; */
    box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.1);
    background-color: white;
    padding: 9px;
    padding-bottom: 27px;
    padding-left: 27px;
    margin-right: 2px;
    margin-left: 2px;
    /* margin-top: 13px; */
    border-radius: 10px;
  }
  .flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .delete-modal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    background-color: #fff;
    border: 1px solid #7f7f7f;
    box-shadow: 24px;
  }
  .admin-table-card {
    /* margin-right: 0%;
    margin-left: 0%; */
    max-height: 90vh;
  }
  .admin-container-padding {
    padding: 0px;
  }
  .admin-grid-item {
    margin-left: 9px;
  }
  .td-box {
    max-width: 100%;
  }
  .tdb-box {
    text-align: center;
    font-size: 16px;
    word-wrap: break-word;
    overflow: hidden;
  }
}
