.email-template-grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Default to 4 items per row */
  gap: 16px; /* Space between grid items */
  padding: 16px;
}

.email-template-grid-item {
  --bg: #fff;
  --hover-bg: #fff;
  --hover-text: #fff;
  height: 300px;
  width: 100%; /* Ensure items span their column width */
  max-height: 300px;
  background-color: var(--bg);
  text-align: center;
  border-radius: 5px;
  transition:
    0.3s cubic-bezier(0.6, 0.4, 0, 1),
    transform 0.15s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
}

.email-template-grid-item img {
  width: 100%; /* Image spans the full width of the div */
  height: 100%; /* Image fills the full height of the div */
  object-fit: cover; /* Crops the image while preserving aspect ratio */
  object-position: top; /* Ensures the upper part of the image is visible */
}
.bottom-text {
  position: absolute;
  bottom: 10px; 
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.email-template-grid-item:hover {
  transform: scale(1.05); /* Slight zoom-in effect */
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2); /* Add a stronger shadow on hover */
  background-color: var(--hover-bg); /* Optional: Change the background color */
}

@media (max-width: 1024px) {
  .email-template-grid-container {
    grid-template-columns: repeat(3, 1fr); /* Switch to 3 items per row */
  }
}

@media (max-width: 768px) {
  .email-template-grid-container {
    grid-template-columns: repeat(2, 1fr); /* Switch to 2 items per row */
  }
}

@media (max-width: 480px) {
  .email-template-grid-container {
    grid-template-columns: 1fr; /* Switch to 1 item per row */
  }
}
