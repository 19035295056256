.header-text {
  font-size: 22px;
  font-weight: bold;
  color: #000;
  margin-right: 10px;
}
.capture-contact-header-container {
  display: flex;
  justify-content: space-between;

  flex-direction: row;
}

.upload-export-container {
  display: flex;
  justify-content: flex-end;
  margin-right: 40px;
  /* margin-bottom: 10px; */
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  background-image: url("../assets/images/bg1.png");
  /* background-size: cover; */
  box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.1);
  background-color: white;
  padding: 9px;
  justify-content: center;
  padding-bottom: 27px;
  padding-left: 27px;
  margin-right: 60px;
  margin-left: 60px;
  /* margin-top: 13px; */
  border-radius: 10px;
}
.btn-container {
  /* margin-top: 40px; */
  display: flex;
  /* width: 70%; */
  justify-content: center;
  /* margin: 10px; */
  gap: 20px;
  padding-top: 20px;

  align-items: center;
}
.btn-inner-container {
  display: flex;
  /* width: 70%; */

  justify-content: space-around;
  align-items: center;
  /* width: 900px; */
}
.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 35%;
  background-color: #fff;
  border: 1px solid #7f7f7f;
  box-shadow: 24px;
}
.scan-modal {
  padding: 40px;
}
.scan-process-modal {
  padding: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.cam-modal-container {
  position: absolute;
  top: 10%;
  left: 20%;
  box-shadow: 24px;
  /* height: 90%; */
}
.cam-modal-box {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  /* margin-right: 15px; */
  cursor: pointer;
  background-color: #a1a1a1;
}
.loading-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loading-lottie {
  width: 150px;
  height: 150px;
  color: #7f7f7f;
}
.Documents-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
  height: 45px;
  border: none;
  padding: 0px 15px;
  border-radius: 5px;
  background-color: rgb(49, 49, 83);
  gap: 5px;
  cursor: pointer;
  transition: all 0.3s;
  margin-right: 10px;
}
.dd-options-modal {
  padding-left: 30px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  padding-right: 20px;
  max-height: 60vh;
  overflow-y: auto;
  /* justify-content: space-around; */
  /* align-items: start; */
}
.dd-modal-box {
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-right: 15px;
  margin-top: 15px;
  cursor: pointer;
}
.edit-line {
  background-color: #666666;
  height: 0.5px;
  margin-left: 30px;
  margin-right: 55px;
  margin-bottom: 10px;
}
.datePicker {
  width: 100%;
  height: 30px;
  border-radius: 5px;
  border-color: 1px solid #c6c6c6;
}
.show-zindex {
  z-index: 100;
}
.mobile-container {
  display: flex;
  width: 100%;
}

.mobile-container > div:first-child {
  max-width: 25%;
  margin-right: 10px;
}

@media only screen and (max-width: 600px) {
  .capture-contact-header-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .upload-export-container {
    transform: scale(0.9);
    display: flex;
    justify-content: flex-end;
    margin-right:-10px;

    /* margin-bottom: 10px; */
  }
  .grid-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    background-image: url("../assets/images/bg1.png");
    box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.1);
    background-color: white;
    border-radius: 10px;
    padding-bottom: 0px;
    /* Padding should be minimal or 0 to avoid pushing the container */
    padding-left: 0px;
    padding-right: 0px;

    /* Center with margin auto */
    margin: 0 auto;

    /* Set width slightly less than 100% to center properly */
    width: 95%;
  }
  .btn-container {
    /* gap:10px; */
    justify-content: center;
    align-items: center;
    /* margin: 15px; */
  }
  .grid-item {
    width: 100vw;
    padding-left: 20px;
    padding-right: 20px;
  }
  .header-text {
    font-size: 15px;
    font-weight: bold;
    color: #000;
    margin-right: 10px;
  }
  .modal-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    background-color: #fff;
    border: 1px solid #7f7f7f;
    box-shadow: 24px;
  }
  .scan-process-modal {
    padding: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 10px;
    align-items: center;
  }
  .cam-modal-container {
    position: absolute;
    top: 5%;
    left: 0%;
    box-shadow: 24px;
    max-height: 90vh;
  }
  .Documents-btn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: fit-content;
    height: 45px;
    border: none;
    padding: 0px 15px;
    border-radius: 5px;
    background-color: rgb(49, 49, 83);
    gap: 5px;
    cursor: pointer;
    transition: all 0.3s;
    margin-right: 20px;
  }
}

.folderContainer {
  width: 40px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}
.fileBack {
  z-index: 1;
  width: 80%;
  height: auto;
}
.filePage {
  width: 50%;
  height: auto;
  position: absolute;
  z-index: 2;
  transition: all 0.3s ease-out;
}
.fileFront {
  width: 85%;
  height: auto;
  position: absolute;
  z-index: 3;
  opacity: 0.95;
  transform-origin: bottom;
  transition: all 0.3s ease-out;
}
.text {
  color: white;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
}
.Documents-btn:hover .filePage {
  transform: translateY(-5px);
}
.Documents-btn:hover {
  background-color: rgb(58, 58, 94);
}
.Documents-btn:hover .fileFront {
  transform: rotateX(30deg);
}
