.contact-details-container {
  background-color: #fff;
  min-height: 80vh;
}
/* From Uiverse.io by gharsh11032000 */
/* The switch - the box around the slider */
.switch {
  font-size: 17px;
  position: relative;
  display: inline-block;
  width: 3em;
  height: 1.5em;
}


/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.contact-details-th {
  min-width: 10px;
  /* max-width: 100px; */
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  inset: 0;
  background: #e6e6e6;
  border-radius: 50px;
  transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
}

.slider:before {
  position: absolute;
  content: "";
  height: 1em;
  width: 1em;
  left: 0.3em;
  bottom: 0.3em;
  background-color: white;
  border-radius: 50px;
  box-shadow: 0 0px 20px rgba(0, 0, 0, 0.4);
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.switch input:checked + .slider {
  background: #5de24e;
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #5de24e;
}

.switch input:checked + .slider:before {
  transform: translateX(1.3em);
  width: 1.5em;
  height: 1.5em;
  bottom: 0;
}
.signup-button {
  font-family: poppins;
  font-size: 15px;
  /* background: royalblue; */
  color: white;
  max-width: 60px;
  /* max-height: 48px; */
  text-align: center;
  /* padding: 0.7em 1em; */
  /* padding-left: 0.9em; */
  display: flex;
  justify-content: "center";
  align-items: center;
  border: none;
  border-radius: 5px;
  overflow: hidden;
  transition: all 0.2s;
  cursor: pointer;
  background-color: #1770e0;
  /* background-image: linear-gradient(to right, #1770e0, #1770e0); */
  /* padding: 10px; */
}
.transparent-input {
  background-color: transparent;
  border: none;
  outline: none;
  color: #333; /* Set your preferred text color */
  font-size: 12px; /* Adjust font size */
  padding: 8px; /* Add padding if needed */
  width: 100%; /* Set width as needed */
}

.transparent-input::placeholder {
  color: #aaa; /* Placeholder color */
  opacity: 0.7;
}

.import-button {
  font-family: poppins;
  font-size: 15px;
  /* background: royalblue; */
  color: white;
  max-width: 60px;
  /* max-height: 48px; */
  text-align: center;
  /* padding: 0.7em 1em; */
  /* padding-left: 0.9em; */
  display: flex;
  justify-content: "center";
  align-items: center;
  border: none;
  border-radius: 5px;
  overflow: hidden;
  transition: all 0.2s;
  cursor: pointer;
  background-image: linear-gradient(to right, #23a466, #23a466);
  /* padding: 10px; */
}

.contact-detail-box {
  margin-left: 15px;
  padding: 25px;
  padding-top: 8px;
}
.contact-container {
  background-color: #fff;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
  padding: 16px;
  margin-top: 16px;
  /* max-height: 65vh;
  min-height: 65vh; */
}
.profile-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: 65vh; */
}
.row-flex {
  display: flex;
  flex-direction: row;
}
.row-flex-card {
  display: flex;
  flex-direction: row;
}
.row-flex-i {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.row-flex-end {
  display: flex;
  flex-direction: row;
  align-items: end;
}
.absolute-row-flex {
  /* position: absolute; */
  display: flex;
  flex-direction: row;
  align-items: end;
}
.profile-circle {
  width: 80px;
  height: 80px;
  border-radius: 50px;
  background-color: #fcead4;
  display: flex;
  justify-content: center;
  align-items: center;
}
.edit-container {
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background-color: #cccccc;
  margin-left: -25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.addr-container {
  max-width: 200px;
  border-radius: 8px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.table-box {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  margin-bottom: 240px;
  margin-left: 80px;
  align-items: end;
  width: 75%;
}
.add-meeting-box {
  cursor: pointer;
  margin-left: 8px;
  height: 25px;
}
.delete-box {
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-bottom: 1px;
  margin-top: 10px;
}
.edit-modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  background-color: #fff;
  border: 1px solid #7f7f7f;
  box-shadow: 24px;
}
.edit-modal-box {
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-top: 10px;
  margin-right: 15px;
  cursor: pointer;
}
.edit-line {
  background-color: #666666;
  height: 0.5px;
  margin-left: 30px;
  margin-right: 55px;
  margin-bottom: 10px;
}
.edit-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 15px;
  margin-right: 10px;
}
.delete-modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 35%;
  background-color: #fff;
  border: 1px solid #7f7f7f;
  box-shadow: 24px;
}
.delete-modal-box {
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-top: 10px;
  margin-right: 15px;
  cursor: pointer;
}
.delete-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}
.delete-btn-box {
  width: 60px;
  height: 30px;
  background-color: #3871a8;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  cursor: pointer;
}
.mtng-edit-box {
  background-color: #666666;
  height: 0.5px;
  margin-left: 30px;
  margin-right: 55px;
  margin-bottom: 10px;
}
.loading-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loading-lottie {
  width: 150;
  height: 150;
  color: #7f7f7f;
}

.table-box-without-card {
  display: none;
}
.contact-detail-modal-scroll {
  max-height: 60vh;
  overflow-y: auto;
  /* margin-left: 10px; */
}

@media only screen and (max-width: 600px) {
  .row-flex-card {
    display: flex;
    flex-direction: column;
  }
  .inside-card {
    display: none;
  }
  .table-box-without-card {
    display: block;
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-left: -2%;
    margin-right: -5%;
  }

  .table-box {
    display: none;
    flex-direction: row;
    margin-top: 8px;
    margin-bottom: 20px;
    /* margin-left: 10px; */
    align-items: end;
    width: 100%;
  }
  .contact-container {
    background-color: #fff;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
    padding: 16px;
    margin-top: 5px;
    margin-left: -2%;
    margin-right: 5%;
  }
  .edit-modal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 50vh;
    transform: translate(-50%, -50%);
    width: 95%;
    background-color: #fff;
    border: 1px solid #7f7f7f;
    box-shadow: 24px;
  }
  .delete-modal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    background-color: #fff;
    border: 1px solid #7f7f7f;
    box-shadow: 24px;
  }
  .mar-left {
    margin-left: 10px;
  }
  th {
    /* padding-top: 12px;
  padding-bottom: 12px; */
    border: 1px solid #fff;
    min-width: 18vh;
    max-width: 25vh;
    text-align: center;
    background-color: #cfd8f9;
    color: #000;
    padding: 5px;
  }
  .contact-detail-box {
    margin-left: 1px;
    /* padding: 25px; */
    padding-top: 8px;
  }
  th {
    min-width: 10vh;
    background-color: #1770e0;
    color: #fff;
  }
}
@media only screen and (max-width: 800px) and (min-width: 600px) {
  .row-flex-card {
    display: flex;
    flex-direction: column;
  }
  .inside-card {
    display: none;
  }
  .table-box-without-card {
    display: block;
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-left: -2%;
    margin-right: -2%;
  }

  .table-box {
    display: none;
    flex-direction: row;
    margin-top: 8px;
    margin-bottom: 20px;
    /* margin-left: 10px; */
    align-items: end;
    width: 100%;
  }
  .contact-container {
    background-color: #fff;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
    padding: 16px;
    margin-top: 16px;
    margin-left: -2%;
    margin-right: 5%;
  }
  .edit-modal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    background-color: #fff;
    border: 1px solid #7f7f7f;
    box-shadow: 24px;
  }
  .delete-modal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    background-color: #fff;
    border: 1px solid #7f7f7f;
    box-shadow: 24px;
  }
  .mar-left {
    margin-left: 10px;
  }
  th {
    /* padding-top: 12px;
  padding-bottom: 12px; */
    border: 1px solid #fff;
    min-width: 18vh;
    max-width: 25vh;
    text-align: center;
    background-color: #cfd8f9;
    color: #000;
    padding: 5px;
  }
  .contact-detail-box {
    margin-left: 1px;
    /* padding: 25px; */
    padding-top: 8px;
  }
  th {
    min-width: 10vh;
  }
}
@media only screen and (max-width: 1000px) and (min-width: 800px) {
  .table-box {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
    margin-bottom: 240px;
    margin-left: 60px;
    align-items: end;
    width: 60%;
  }

}
