.mail-history-container {
  display: flex;
  justify-content: center;
}
.mail-history-box {
  min-width: 97%;
  max-width: 97%;
  max-height: 80vh;
  min-height: 70vh;
  background-color: #fff;
  /* padding: 5px; */
}
@media only screen and (max-width: 600px) {
  .mail-history-box {
    min-width: 97%;
    max-width: 97%;
    max-height: 82vh;
    min-height: 82vh;
    background-color: #fff;
  }
  td {
    padding: 10px;
    max-width: 20vh;
  }
}
.no-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
