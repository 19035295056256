.levelname-box {
  margin-bottom: 25px;
  padding: 35px;
  width: 1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #c4c4c4;
}
.level-box {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 25px;
  margin-left: 42px;
  width: 90%;
  border: 1px solid #c4c4c4;
}

.company-container {
  /* display: grid;
  grid-template-columns: repeat(3, 1fr); */
  /* background-image: url("./assets/images/bg1.png"); */
  box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.1);
  min-height: 73vh;
}
.contact-card {
  padding: 25px;
  border-radius: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 6%;
  margin-left: 6%;
  margin-bottom: 12px;
  margin-top: 12px;
}

.contact-card:hover {
  box-shadow: 0 8px 10px 0 black;
  cursor: pointer;
  box-shadow: 0 8px 16px 0 gray;
  transition: all 0.3s;
}
.company-card {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  .level-box {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 25px;
    margin-left: 20px;
    width: 90%;
    border: 1px solid #c4c4c4;
  }
}
