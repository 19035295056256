.campaign-card-grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  padding: 16px;
}
.campaign-card {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  font-family: "Arial", sans-serif;
  /* margin: 16px auto;  */
  /* width: calc(100% - 32px);  */
  width: 100%;
  cursor: pointer;
  transition:
    transform 0.3s ease-in-out,
    box-shadow 0.3s ease-in-out,
    border-color 0.3s ease-in-out;
}

.campaign-card:hover {
  transform: translateY(-5px); /* Slightly lifts the card */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Adds a more prominent shadow */
  border-color: #fff; /* Subtle border color change */
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* Allows wrapping for smaller screens */
  margin-bottom: 16px;
}

.left-section {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
}

.left-section-icon {
  background: #ec4d1d;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.details h3 {
  margin: 0;
  font-size: 16px; /* Reduced size for smaller screens */
  color: #333;
}

.details p {
  margin: 4px 0 0;
  font-size: 12px; /* Adjusted for mobile readability */
  color: #666;
}

.right-section {
  display: flex;
  align-items: center;
  gap: 6px; /* Adjusted gap for smaller screens */
  font-size: 12px; /* Reduced size for mobile */
  color: #666;
}

.tags {
  margin: 8px 0;
  flex-wrap: wrap; /* Allows wrapping on smaller screens */
}

.tag {
  display: inline-block;
  margin-right: 8px;
  padding: 4px 6px; /* Adjusted padding for smaller screens */
  background: #eef;
  color: #555;
  border-radius: 4px;
  font-size: 10px; /* Reduced size for mobile */
}



.stats {
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
  border-top: 1px solid #eee;
  width: 100%; /* Full width for mobile */
}

.bottom-stats {
  display: flex;
  justify-content: flex-end;
}

.stat {
  display: flex;
  flex-direction: column; /* Stack stats for better readability on mobile */
  text-align: center;
}

.stat h4 {
  margin: 0;
  font-size: 16px; /* Adjusted size for smaller screens */
  color: #333;
}

.stat p {
  font-size: 10px; /* Adjusted size for readability */
  color: #666;
  margin: 0;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  flex-wrap: wrap; /* Allows wrapping for smaller screens */
}

.status {
  font-size: 12px; /* Adjusted size for mobile */
  color: #28a745;
  background: #fff;
  border: 1px solid #28a745;
  padding: 4px 10px; /* Reduced padding for smaller screens */
  border-radius: 20px;
  display: flex;
  align-items: center;
}

.running-dot {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #28a745;
  margin-right: 5px;
}

.menu-button {
  border: none;
  background: transparent;
  font-size: 16px; /* Adjusted size for mobile */
  color: #666;
  cursor: pointer;
}

/* Responsive Design: Mobile Adjustments */
@media (max-width: 600px) {
  .campaign-card {
    width: calc(100%); /* Reduce margin for smaller screens */
    /* margin: 10px;  */
  }

  .header {
    /* flex-direction: column;  */
    /* align-items: flex-start;  */
    justify-content: space-between;
  }

  .left-section {
    flex-direction: column; /* Stack content */
    align-items: flex-start;
  }

  .details h3 {
    font-size: 14px; /* Further reduce size for small screens */
  }

  .details p {
    font-size: 10px;
  }

  .right-section {
    font-size: 10px; /* Smaller size for less prominent text */
  }

  .tags .tag {
    font-size: 10px; /* Smaller tag text */
    margin-right: 4px; /* Reduce spacing */
  }

  .stat h4 {
    font-size: 14px; /* Reduced for mobile */
  }

  .stat p {
    font-size: 9px;
  }

  .status {
    font-size: 10px; /* Smaller status text */
  }
  .campaign-card-grid-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 16px;
    padding: 16px;
  }
}
