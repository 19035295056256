/* Default Flexbox styling for desktop and tablet views */
.action-buttons-container {
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
  gap: 16px;
}

/* Mobile view: Stack the elements vertically and align them to the left */

.pages-box {
  /* display: flex; */
  /* flex-direction: row; */
  /* justify-content: center; */
  /* align-items: center; */
  /* height: 40vh; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.view-pages-box {
  /* display: flex; */
  /* flex-direction: row; */
  /* justify-content: center; */
  /* align-items: center; */
  /* height: 80vh; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-card {
  background-image: url("../assets/images/card.png");
  background-size: cover;
  border-radius: 5px;
  height: 40vh;
  width: 50%;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 18px;
  margin-left: 18px;
  box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.2);
}
.page-card:hover {
  box-shadow: 0 8px 10px 0 black;
  box-shadow: 0 8px 16px 0 gray;
  transition: all 0.3s;
  transform: scale(1.05);
}
.icon-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fffde3;
  height: 60px;
  width: 60px;
  border-radius: 30px;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
}
.card-text {
  /* font-style: italic; */
  font-weight: bold;
  color: #4c4c4c;
  margin-top: 20;
}
.dashboard-header {
  background: linear-gradient(#0d7bd3, transparent);
  background-color: #284b8c;
  height: 14vh;
  display: flex;
  margin-bottom: 10px;
  justify-content: flex-start;
  align-items: center;
}
.logo-box {
  margin-left: 18px;
  border-radius: 10px;
  /* height: 70px; */
  /* margin-top: 20px; */

  /* width: 80px; */
  /* background-color: #fff; */
  /* display: flex;
  justify-content: center; */
}
.dashboard-data-table-margin {
  /* margin-top: -40px; */
}

.dashboard-main-container {
  /* background-color: #eceeef; */
  background-color: #fff;
  min-height: 90vh;
}
.dashboard-main-container-header {
  font-size: 27px;
  font-weight: bold;
  color: #fff;
  margin-left: 10px;
}
.dashboard-inorbit-logo {
  height: 100px;
  width: 200px;
  object-fit: contain;
  /* margin-top: 10px; */
}

@media only screen and (max-width: 600px) {
  .pages-box {
    flex-direction: column;
  }
  .page-card {
    background-image: url("../assets/images/card.png");
    background-size: cover;
    border-radius: 5px;
    height: 40vh;
    width: 95%;
    margin-bottom: 10px;
    margin-top: 10px;
    /* padding-left: 10px; */
    /* padding-right: 10px; */
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 18px;
    margin-left: 18px;
    box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.2);
  }

  .dashboard-main-container-header {
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    margin-left: 5px;
  }
  .dashboard-inorbit-logo {
    height: 100px;
    width: 180px;
    object-fit: contain;
    border-radius: 10px;
    margin-left: 10px;
    /* margin-top: -15px; */
  }
  .logo-box {
    margin-left: 10px;
    border-radius: 10px;
    /* height: 70px; */
    /* width: 180px; */
    /* background-color: #fff; */
    /* display: flex;
    justify-content: center; */
  }
  .action-buttons-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .action-buttons-grid-item {
    text-align: start;
    /* width: 100%; */
  }
  .dashboard-data-table-margin {
    margin-top: 0px;
  }
}
